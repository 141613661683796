import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyDHtYNmU-imrIioCmIWLu30o6DKQIwGko8",
  authDomain: "djmachadoapp.firebaseapp.com",
  databaseURL: "https://djmachadoapp-default-rtdb.firebaseio.com",
  projectId: "djmachadoapp",
  storageBucket: "djmachadoapp.appspot.com",
  messagingSenderId: "413133101144",
  appId: "1:413133101144:web:57b38b45b2f834347d922d",
  measurementId: "G-59D4QY2BS9"
};

firebase.initializeApp(config);

export default firebase.database();